import * as queryString from 'query-string';
import * as React from 'react';
import logo from './topola.jpg';
import {Card, Grid, Image} from 'semantic-ui-react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {Media} from './util/media';

/** Link that loads a GEDCOM file from URL. */
function GedcomLink(props: {url: string; text: string}) {
  return (
    <Link
      to={{pathname: '/view', search: queryString.stringify({url: props.url})}}
    >
      {props.text}
    </Link>
  );
}

function formatBuildDate(dateString: string) {
  return dateString.slice(0, 16);
}

/** The intro page. */
export function Intro() {
  const contents = (
    <>
      <p>
        <FormattedMessage
          id="intro.description"
          defaultMessage={
            'Topola Genealogy is a genealogy tree viewer that lets you' +
            ' browse the structure of the family.'
          }
        />
      </p>
      <p>
        <FormattedMessage
          id="intro.instructions"
          defaultMessage={
            'Use the OPEN FILE or LOAD FROM URL buttons above to load' +
            ' a GEDCOM file. You can export a GEDCOM file from most of the' +
            ' existing genealogy programs and web sites.'
          }
        />
      </p>
      <ul>
        <li>
          <GedcomLink
            url="https://topola.h4ck.me/moje.ged"
            text="Moje"
          />{' '}
          (<FormattedMessage id="intro.from" defaultMessage="from" />{' '}
          <a href="https://topola.h4ck.me/moje.ged">
            gramps
          </a>
          )
        </li>
      </ul>
      <p className="ui right aligned version">
        version: {formatBuildDate(process.env.REACT_APP_GIT_TIME!)} (
        <a
          href={`https://github.com/PeWu/topola-viewer/commit/${process.env.REACT_APP_GIT_SHA}`}
        >
          {process.env.REACT_APP_GIT_SHA}
        </a>
        )
      </p>
    </>
  );

  return (
    <div id="content">
      <div className="backgroundImage" />
      <Card className="intro">
        <Card.Content as={Media} at="large">
          <Card.Header>
            <FormattedMessage
              id="intro.title"
              defaultMessage="Topola Genealogy Viewer"
            />
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Grid as={Media} at="large">
            <Grid.Row>
              <Grid.Column width={5}>
                <Image src={logo} alt="Topola logo" />
              </Grid.Column>
              <Grid.Column width={11}>{contents}</Grid.Column>
            </Grid.Row>
          </Grid>
          <Media at="small">
            <Image
              src={logo}
              alt="Topola logo"
              centered={true}
              size="tiny"
              className="blockImage"
            />
            {contents}
          </Media>
        </Card.Content>
      </Card>
    </div>
  );
}
